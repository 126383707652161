/* Large media sizes */
* {
    margin: 0;
    padding: 0;
}

header,
footer {
    overflow: auto;
    margin: 0;
}

footer {
    background: #eee;
}

.content {
    width: 1488px;
    margin: 0 auto;
    padding: 0;
    overflow-x: auto;
}

.width-12 {
    width: 1438px;
    margin: 0 25px;
}

.width-11 {
    width: 1314px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-10 {
    width: 1190px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-9 {
    width: 1066px;
    margin: 0 25px;
    display: block;
    float: left;
    overflow-y: hidden;
}

.width-8 {
    width: 942px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-7 {
    width: 818px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-6 {
    width: 694px;
    margin: 0 25px;
    padding: 0;
    display: block;
    float: left;
}

.width-5 {
    width: 570px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-4 {
    width: 446px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-3 {
    width: 322px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-2 {
    width: 198px;
    margin: 0 25px;
    display: block;
    float: left;
}

.width-1 {
    width: 74px;
    margin: 0 25px;
    display: block;
    float: left;
}

.right {
    float: right !important;
    text-align: right !important;
}

.left {
    float: left !important;
    text-align: left !important;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.right-margin {
    margin-right: 50px;
}

.centre {
    margin: 0 auto;
    text-align: center;
}

.clear {
    clear: both;
}

.cl-lft {
    clear: left;
}

.cl-rght {
    clear: right;
}

.oauto {
    overflow: auto;
}

.small {
    font-size: 0.75em;
}

img {
    border: none;
}

.quarters {
    width: 20%;
    padding: 0;
    margin: auto 2.5%;
    display: block;
    float: left;
    margin-top: 60px;
    margin-bottom: 60px;
}

.third {
    width: 30%;
    float: left;
    margin: 0 1.5%;
}

/* Specific Wireframes Styles */

.cblock {
    position: relative;
    /*border: 1px solid #999;
    background: #f1f2f3;
    margin-bottom: 20px !important;
    padding: 8px 12px; overflow: auto;*/
    box-sizing: border-box;
}

.centred {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    overflow: auto;
}

.empty-textarea {
    min-height: 50px;
    background: red;
}

a.block-btn {
    display: block;
    text-align: center;
    background: #444;
    color: #fff;
    padding: 8px 15px;
    margin: 0 0 5px 0;
}

.drop-highlight {
    height: 100px;
    background: #dfdfdf;
}

/* CMS styles */

/* Less than 960 breakpoint */
/* Below 960 we start by scaling */

@media only screen and (max-width: 1520px) {

    .content {
        width: 95%;
        margin: 0 auto;
        padding: 0;
    }

    .width-12 {
        width: 97.5%;
        margin: 0 1.25%;
    }

    .width-11 {
        width: 89.2%;
        margin: 0 1.25%;
    }

    .width-10 {
        width: 80.8%;
        margin: 0 1.25%;
    }

    .width-9 {
        width: 72.5%;
        margin: 0 1.25%;
    }

    .width-8 {
        width: 64.2%;
        margin: 0 1.25%;
    }

    /* .width-7 {
		width: 55.8%;
		margin: 0 1.25%;
	} */
    .width-6,
    .width-7,
    .width-5 {
        width: 47.5%;
        margin: 0 1.25%;
    }

    /* .width-5 {
		width: 39.2%;
		margin: 0 1.25%;
	} */
    .width-4 {
        width: 30.8%;
        margin: 0 1.25%;
    }

    .width-3 {
        width: 22.5%;
        margin: 0 1.25%;
    }

    .width-2 {
        width: 14.1%;
        margin: 0 1.25%;
    }

    .width-1 {
        width: 5.8%;
        margin: 0 1.25%;
    }

    .width-8 .width-8,
    .width-9 .width-9 {
        width: 100%;
    }
}

@media only screen and (max-width: 800px) {
    .width-6 {
        width: 75%;
        margin: 0 1.25%;
    }
}

@media only screen and (max-width: 680px) {
    .content.admin {
        width: 95%;
    }
}

/* Less than 720 breakpoint */
/* Switch to single column for mobile mobile */

@media only screen and (max-width: 600px) {

    .content {
        width: 95%;
        margin: 0 auto;
        padding: 0;
    }

    .width-12,
    .width-11,
    .width-10,
    .width-9,
    .width-8,
    .width-7,
    .width-6,
    .width-5,
    .width-4,
    .width-3,
    .width-2,
    .width-1,
    .form-block {
        width: 95%;
        margin: 0 2.5% 0 1.5%;
    }

}