/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,500;0,600;0,700;1,500&display=swap'); */
/* General styles - fonts etc */
@font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Bold'), url('../fonts/Gilroy-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Light'), url('../fonts/Gilroy-Light.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Medium'), url('../fonts/Gilroy-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Regular'), url('../fonts/Gilroy-Regular.woff') format('woff');
}

/* Core styles */
body {
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 300;
}

h1, h2, h3, h4, h5 {
    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: 600;
}

h2 { font-size: 52px; text-align: center; margin-bottom: 42px; }
h3 { font-size: 42px; margin-bottom: 22px; font-weight: 300; }
h4 { font-size: 26px; margin-bottom: 18px; font-weight: 300; }

p { margin-bottom: 22px; }

a { text-decoration: none; border-bottom: 1px solid #000; padding-bottom: 1px; color: #000; transition: 0.35s; }
a:hover { border-bottom-color: rgba(0,0,0,0); }

button { cursor: pointer; }

.bold-italic { font-weight: 600; font-style: italic; }

a.btn, button.btn {
    display: block;
    background: #73F1FF;
    border-radius: 12px;
    padding: 12px 42px;
    color: #484A66;
    border: none;
    font-family: "Raleway", arial, sans-serif;
}
a.btn:hover, button.btn:hover {
    opacity: 0.8;
}

.tny-mtop { margin-top: 8px !important; } 

/* Header */
header {
    padding: 12px 0 28px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    color: #fff;
}
header .header-bg {
    background: #2C2D7A;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
header .content { overflow-y: hidden; }
header .logo {
    margin-top: 16px;
}
header .logo a {
    border-bottom: none;
}
header .logo img {
    width: 100%;
    height: auto;
    max-width: 250px;
}
header nav {
    text-align: right;
    padding: 0 0 10px 0;
}
header nav ul {
    list-style: none;
    margin-top: 14px;
}
header nav ul li {
    display: inline-block;
    margin: 0 25px;
}
header nav ul li a {
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 25px 22px;
    transition: 0.35s;
    border-bottom: none;
}
header nav ul li a:hover {
    opacity: 0.7;
}

header a.favourite {
    display: block;
    background: #73F1FF url('../img/icons/star.svg') top 60px right 22px / 32px 32px no-repeat;
    width: 120px;
    height: 120px;
    position: fixed;
    bottom: 0;
    right: 0;
    border: none;
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
    padding: 96px 0 0 38px;
    font-size: 12px;
    text-align: center;
    box-sizing: border-box;
    color: #484A66;
    font-weight: 600;
    transition: 0.5s;
}
header a.favourite:hover {
    opacity: 0.65;
}
header .width-9 ul.contact-us {
    font-size: 0.85em;
    margin-right: 25px;
}
header .width-9 ul.contact-us li {
    margin: 0 0 0 24px;
}
header .width-9 ul.contact-us li a {
    background: none;
    color: #fff;
    z-index: 1;
    padding: 0;
    margin: 0 0 8px 0;
    border: none;
}

/* Slideshow */
.header-img {
    background: #2C2D7A url('../img/home/headline.webp') center top -82px / 100% auto no-repeat;
    overflow: auto;
    position: relative;
    padding-bottom: 72px;
}
.header-img .content {
    position: relative;
}
.header-img .content .title-tag {
    max-width: 520px;
    margin-top: 280px;
    float: left;
    width: 65%;
}
.header-img .content .title-tag h1 {
    font-size: 36px;
    font-weight: 300;
    text-align: left;
    margin-bottom: 16px;
}
.header-img .content .title-tag h3 {
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    margin-bottom: 16px;
}
.header-img .content .devices-cont {
    width: 42%;
    height: 40vw;
    float: right;
    margin-top: -200px;
    margin-bottom: 42px;
    background: url('./../img/home/devices-23.webp') top right / contain no-repeat;
}
.header-img .intro {
    font-size: 20px;
    margin-top: 48px;
    text-align: left !important;
    clear: both;
}
.header-img .explainer {
    position: relative;
    height: 390px;
    float: left;
}
.header-img .explainer iframe {
    width: 100%;
    height: auto;
    border: none;
}

.header-img .review {
    clear: left;
    float: left;
    margin-top: 72px;
    max-width: 520px;
}
.header-img .review .reviewer-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    border: 3px solid #CBFAFF;
}
.header-img .review .review-content {
    background: #CBFAFF;
    margin: 11px 0 0 100px;
    color: #2C2D7A;
    padding: 18px 22px 18px 72px;
    border-radius: 0 16px 16px 0;
}
.header-img .review .review-content p {
    margin: 0;
    font-size: 0.95em;
}
.header-img .review .review-content p.reviewer {
    font-size: 0.85em;
    margin-top: 8px;
    text-align: right;
    opacity: 0.65;
}

/* Features */
.features, .questions, .contact {
    background: #CBFAFF;
    color: #2C2D7A;
    padding: 80px 0;
    position: relative;
}
.contact.dark {
    background: #2C2D7A;
    color: #fff;
    padding-top: 120px;
}
.features:after, .contact:after {
    background: linear-gradient(-45deg, #ffffff 12px, transparent 0), linear-gradient(45deg, #ffffff 12px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 24px 24px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 24px;
}
.features h2 {
    text-align: left;
    font-size: 32px;
    margin-bottom: 22px;
}
.features .prominent, .questions .prominent { font-size: 18px; }
.features .feature {
    margin-bottom: 36px;
}
.features .feature > div {
    margin-left: 110px;
}
.features .feature img {
    max-width: 55px;
    max-height: 55px;
    float: left;
    margin: 0 22px;
}
.features .feature h4 {
    color: #2C2D7A;
    text-align: center;
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    margin: 0 auto 5px 0;
}
.questions .question {
    margin-top: 12px;
}
.questions .question h4 a {
    text-decoration: none;
    border-bottom: none;
    color: #2C2D7A;
    padding-left: 42px;
    background: url('../img/core/plus.svg') left center / 20px 20px no-repeat;
    display: block;
}
.questions .question.openned h4 a {
    background-image: url('../img/core/minus.svg');
}
.questions .question > div {
    overflow: hidden;
    display: none;
}
.questions .question > div.open {
    margin-bottom: 32px;
    display: block;
    background: rgba(255,255,255,0.25);
    padding: 22px;
    border-radius: 12px;
}
.questions .question>div.open p:last-child {
    margin-bottom: 0;
}

/* How it works */
.how {
    margin: 92px 0;
    color: #2C2D7A;
}
.how .panel {
    position: relative;
    overflow: visible;
    margin: 0 auto;
}
.how .panel h2 {
    margin-bottom: 12px;
}
.how .panel p.centred {
    text-align: center;
    font-size: 20px;
}

.how .panel .panel-content {
    position: relative;
    background: url('../img/icons/connectors.svg') top 119px left 157px / 551px 856px no-repeat;
    height: 1180px;
    width: 942px;
}

.how .panel .item {
    width: 268px;
    text-align: center;
    position: absolute;
}
.how .panel .item.no-one { top: 55px; left: 0; }
.how .panel .item.no-two { top: 144px; left: auto; right: 38px; }
.how .panel .item.no-three { top: 444px; left: 296px; }
.how .panel .item.no-three img { height: 154px; }
.how .panel .item.no-four { top: 716px; left: 4px; }
.how .panel .item.no-four img { height: 150px; }
.how .panel .item.no-five { top: 847px; left: 560px; }
.how .panel .item.no-five img { width: 172px; height: 172px; }
.how .panel .item h4 {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 24px;
}
.how .panel .item img {
    height: 160px;
    margin: 0 auto 22px auto;
    display: block;
}

/* Get OmegaLife */
.get-it {
    background: #2C2D7A;
    padding: 92px 0;
}
.get-it .package {
    background: #3C3D8F;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 32px 36px;
}
.get-it .package.sml + .package.sml {
    margin-top: 32px;
}
.get-it .package.mtop {
    margin-top: 32px;
    float: left;
}
.get-it .package h3 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 8px;
}
.get-it .package h3.mbtm { margin-bottom: 20px; }
.get-it .package h3 span {
    font-weight: 300;
    font-style: italic;
}
.get-it .package h4 {
    font-size: 22px;
    font-style: italic;
    margin-bottom: 32px;
    opacity: 0.5;
}
.get-it .package a.btn.block, .get-it .package button.btn.block {
    display: block;
    text-align: center;
    margin-top: 32px;
    font-weight: 600;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
}
.get-it .package a.btn.block.faded, .get-it .package button.btn.block.faded {
    background: #171857;
    color: #B5B6FF;
    font-style: italic;
}
.get-it .package ul {
    list-style: none;
    min-height: 246px;
}
.get-it .package.bespoke p {
    width: 47.5%;
    float: left;
    clear: left;
}
.get-it .package.sml ul, .get-it .package.bespoke ul {
    min-height: 0px;
}
.get-it .package.bespoke ul {
    width: 45%;
    margin-left: 5%;
    display: inline-block;
}
.get-it .package ul li {
    padding-left: 32px;
    background: url('../img/icons/tick.png') left top 5px / 12px auto no-repeat;
    margin-bottom: 22px;
}
.get-it .package ul li.no-tick {
    background-image: none;
}
.get-it .faded.sml {
    padding-top: 42px;
    clear: left;
    font-size: 14px;
    font-style: italic;
    opacity: 0.5;
}

/* Contact form */
.contact p {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.6em;
}
.contact p:last-child {
    font-size: 18px;
    font-weight: 300;
}

.contact .form input, .contact .form textarea {
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    background: #fff;
    padding: 14px 25px;
    border: none;
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 18px;
    color: #2C2D7A;
    font-weight: 600;
    margin-bottom: 22px;
}
.contact .form textarea {
    margin-top: -15px;
}
.contact .form input:focus,
.contact .form textarea:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(44,45,122,0.5);
}
.contact .form textarea {
    height: 150px;
    resize: none;
}
.contact .form button.btn {
    font-weight: 600;
    font-size: 16px;
}

/* Footer */
footer {
    background: #fff;
    color: #2C2D7A;
    font-size: 13px;
    padding: 28px 0;
}

footer .partners {
    width: 242px;
    margin: 0 auto;
    overflow: auto;
    margin-bottom: 28px;
}
footer .partners p {
    float: left;
    margin-top: 36px;
    font-size: 16px;
    font-weight: 600;
}
footer img.partner-logo {
    width: 80px;
    height: 92px;
    float: right;
}
footer .final {
    border-top: 1px solid #D8D8D8;
    padding-top: 28px;
}
footer .final p.email {
    margin: 0;
    padding-left: 32px;
    background: url('../img/icons/email.png') left center / 20px auto no-repeat;
}
footer .final a {
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid #fff;
    display: inline-block;
    color: #2C2D7A;
    transition: 0.3s;
}
footer .final a:hover {
    border-color: #73F1FF;
}
footer .final .right {
    padding-right: 42px;
}

/* Lightboxes */
.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.65);
    z-index: 99;
}
.cover .cover-box {
    width: 480px;
    background: #fff;
    border-radius: 12px;
    padding: 24px 24px 12px 24px;
    box-sizing: border-box;
    margin: 200px auto 0 auto;
    color: #2C2D7A;
}
.cover .cover-box.big {
    width: 800px;
    margin-top: 10vh;
    max-height: 80vh;
    overflow: auto;
}
button.close-box {
    float: right;
    width: 24px;
    height: 24px;
    font-size: 0;
    bordeR: none;
    background: url('../img/core/close.svg') center center / 24px 24px no-repeat;
}
.cover .cover-box h3 {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 1px solid #CBFAFF;
}
.cover .cover-box ul {
    margin-left: 32px;
}
.cover .cover-box ul li {
    margin-bottom: 12px;
}

.cover .cover-box.big h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 42px;
}
.cover .cover-box.big .input {
    margin: 15px 12px;
}
.cover .cover-box.big .input label {
    color: #999;
    font-size: 0.9em;
    display: block;
}
.cover .cover-box.big .input input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 25px;
    border: 1px solid #999;
    background: none;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 16px;
    color: #2C2D7A;
    border-radius: 2px;
}
.cover .cover-box.big .input input:focus {
    border-color: #2C2D7A;
    outline: none;
}
.cover .cover-box button.btn {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 16px;
    color: #2C2D7A;
    margin: 25px 12px;
}

p.error {
    background: rgb(241, 180, 180);
    color: rgb(84, 25, 25);
    padding: 12px 25px;
    border-radius: 8px;
}
p.validation-error {
    color: rgb(183, 39, 39);
    margin-top: 4px;
    font-size: 0.85em;
}

.flash-msg {
    background: rgb(183, 39, 39);
    color: #fff;
    padding: 12px 18px;
    border-radius: 12px;
    margin-bottom: 22px;
}
.flash-msg.success {
    background:rgb(47, 112, 21);
}
.flash-msg p {
    margin: 0;
}

input.hidden {
    opacity: 0;
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0 !important;
    overflow: hidden;
}

.contact .val-error {
    font-size: 14px;
    font-weight: 300;
    color:rgb(183, 39, 39);
    margin: -15px 0 22px 0;
}

/* Smaller screen specific styles */
@media only screen and (max-width: 1470px) {

    .how .panel .panel-content {
        width: 780px;
        height: 960px;
        background-size: 433px 672px;
        margin-left: auto;
        margin-right: auto;
    }
    .how .panel .item {
        width: 240px;
    }
    .how .panel .item img { height: 130px; margin-bottom: 16px; }
    .how .panel .item.no-one { top: 55px; left: 32px; }
    .how .panel .item.no-two { top: 110px; left: auto; right: 32px; }
    .how .panel .item.no-three { top: 370px; left: 256px; }
    .how .panel .item.no-three img { height: 110px; }
    .how .panel .item.no-four { top: 596px; left: 42px; }
    .how .panel .item.no-four img { height: 100px; }
    .how .panel .item.no-five { top: 688px; left: 460px; }
    .how .panel .item.no-five img { width: 122px; height: 122px; }
    .how .panel .item h4 {
        font-size: 20px;
    }

    .questions .width-6 {
        width: 90%;
        max-width: 720px;
    }

}

@media only screen and (max-width: 1450px) {

    .header-img .content .title-tag { margin-top: 190px; }
    .header-img .content .devices-cont { margin-top: -170px; }

}

@media only screen and (max-width: 1275px) {

    .header-img .content .title-tag { width: 35%; }
    .header-img .content .title-tag h1 { font-size: 32px; }
    .header-img .content .title-tag h3 { font-size: 20px; width: 90%; }
    .header-img .content .devices-cont { margin-top: -140px; }
    .header-img .review { margin-top: 42px; }

    .how .panel.width-8.centred {
        width: 90%;
    }

    .spacer { display: none; }
    .contact .width-4.form {
        width: 35%;
    }
    .contact .width-4.form {
        width: 60%;
    }

}

@media only screen and (max-width: 1168px) {

    .get-it .package ul {
        min-height: 320px;
    }

    header nav ul li { margin: 0; }
    header nav ul li a.btn { margin-left: 12px; }

    .header-img .content .title-tag { margin-top: 120px; }
    .header-img .content .devices-cont { margin-top: -120px; }

}

@media only screen and (max-width: 1080px) {

    .header-img .review {
        background: #CBFAFF;
        padding: 18px 10px 18px 22px;
        border-radius: 16px;
        max-width: 45%;
        position: relative;
    }
    .header-img .review .reviewer-photo { 
        width: 80px; 
        height: 80px; 
        position: absolute;
        top: 50%;
        left: 18px;
        margin-top: -40px;
    }
    .header-img .review .review-content { margin-left: 100px; padding: 0; }


}

@media only screen and (max-width: 1050px) {

    .header-img .content .title-tag { margin-top: 170px; }
    .header-img .content .title-tag h1 { font-size: 24px; }
    .header-img .content .title-tag h3 { font-size: 18px; font-weight: 500; }
    .header-img .content .devices-cont { margin-top: -100px; }
    .header-img .content .explainer { height: 380px;}
    .header-img .content .intro { margin-bottom: 22px; font-size: 18px; }

}

@media only screen and (max-width:1020px) {

    .features .width-2 {
        width: 30.8%;
        margin: 0 1.25%;
    }
    .features .width-2:first-child, .features .width-2:nth-child(3), .features .width-2:nth-child(2) {
        margin-bottom: 42px;
    }
    .features .width-2:nth-child(4) {
        clear: left;
    }


}

@media only screen and (max-width: 948px) {

    .header-img { background-image: url('./../img/home/headline-tab.webp'); }
    .header-img .content .title-tag { margin-top: 130px; }
    .header-img .content .title-tag h1 { font-size: 22px; padding-top: 22px; }
    .header-img .content .devices-cont { margin-top: -80px; }
    .header-img .content .intro { margin-bottom: 80px; font-size: 16px; }

    .how .content { overflow: hidden; }
    .how .panel.width-8.centred {
        width: 95%;
        overflow: visible;
    }
    .how .panel .panel-content {
        width: 740px;
        height: 952px;
        background-size: 433px 672px;
    }
    .how .panel .item {
        width: 210px;
    }
    .how .panel .item img { height: 130px; margin-bottom: 16px; }
    .how .panel .item.no-one { top: 55px; left: 38px; }
    .how .panel .item.no-two { top: 110px; left: auto; right: 32px; }
    .how .panel .item.no-three { top: 370px; left: 272px; }
    .how .panel .item.no-three img { height: 110px; }
    .how .panel .item.no-four { top: 596px; left: 62px; }
    .how .panel .item.no-four img { height: 100px; }
    .how .panel .item.no-five { top: 688px; left: 460px; }
    .how .panel .item.no-five img { width: 122px; height: 122px; }
    .how .panel .item h4 {
        font-size: 20px;
    }

    .questions .width-6 h4 { font-size: 22px; }

}

@media only screen and (max-width: 880px) {

    header .width-3.logo, header .width-9 { width: 47%; margin: 0 1.5%; }
    header nav ul li a { display: none; }

    .header-img .content .title-tag { margin-top: 100px; }
    .header-img .content .title-tag h3 { font-size: 16px; font-weight: 300; }


    h2 { font-size: 36px; }

    footer .final .width-6 {
        width: 90%;
        margin: 0 auto;
        float: none;
        text-align: center !important;
    }
    footer .final .width-6 p.email {
        margin-bottom: 12px;
        max-width: 120px;
        margin-left: auto;
        margin-right: auto;
    }

    .get-it .package {
        width: 97%;
        margin: 18px 1.5%;
        min-height: 0;
    }
    .get-it .package ul {
        min-height: 0;
        margin-bottom: 52px;
    }
    .get-it .package h3 {
        font-size: 24px;
    }

}

@media only screen and (max-width: 800px) {
    .header-img .content .intro { font-size: 16px; width: 95%; float: none; margin-bottom: 12px; overflow: auto; }
    .header-img .explainer { width: 95%; margin-left: 2.5%; }
}

@media only screen and (max-width: 768px) {

    .how .panel .panel-content {
        width: 640px;
        height: 820px;
        background-size: 338px 552px;
        background-position: top 129px left 137px;
    }
    .how .panel .item {
        width: 190px;
    }
    .how .panel .item p { font-size: 14px; }
    .how .panel .item img { height: 110px; margin-bottom: 16px; }
    .how .panel .item.no-one { top: 72px; left: 32px; }
    .how .panel .item.no-two { top: 114px; left: auto; right: 32px; }
    .how .panel .item.no-three { top: 342px; left: 212px; }
    .how .panel .item.no-three img { height: 84px; }
    .how .panel .item.no-four { top: 516px; left: 52px; }
    .how .panel .item.no-four img { height: 80px; }
    .how .panel .item.no-five { top: 578px; left: 372px; }
    .how .panel .item.no-five img { width: 102px; height: 102px; }
    .how .panel .item h4 {
        font-size: 18px;
    }

}

@media only screen and (max-width: 720px) {

    .header-img { background-position: top 0 center; }
    .header-img .content .devices-cont { background-image: url('./../img/home/devices-mob.webp'); }
    .header-img .content .title-tag { margin-top: 140px; width: 95%; max-width: 95%; float: none; }
    .header-img .content .title-tag h1 { font-size: 22px; }
    .header-img .content .devices-cont { 
        margin-top: 0;
        margin-bottom: 0;
    }

    .contact .width-4,
    .contact .width-4.form {
        width: 95%;
    }

}

@media only screen and (max-width: 645px) {

    .how .panel .panel-content {
        width: 95%;
        height: auto;
        background-image: none;
        margin: 32px auto 0 auto;
    }
    .how .panel .item {
        width: 190px;
        position: relative;
        display: block;
        margin: 0 auto;
        top: auto !important;
        right: auto !important;
        left: auto !important;
        margin-bottom: 42px;
    }
    .how .panel .item:last-child {
        margin-bottom: 0;
    }

    .header-img .content .devices-cont { margin-top: 40px; }

    .get-it .package.bespoke p, .get-it .package.bespoke ul {
        width: 100%;
        float: none;
    }
    .get-it .package.bespoke ul { margin-bottom: 12px; }

}

@media only screen and (max-width: 560px) {

    header { position: absolute; }
    header .width-9 { display: none; }
    header .width-3.logo { width: 97%; margin: 95px auto 0 auto; text-align: center; }
    header .width-3.logo img { margin: 0 auto; }

    .header-img { background-image: url('./../img/home/headline-tab.webp'); background-position: top -65px center; }
    .header-img .content .title-tag { margin-top: 270px; }
    .header-img .explainer { height: 300px; }

    .header-img .review { float: none; max-width: 95%; box-sizing: border-box; }
    .header-img .content .devices-cont { float: none; margin: 32px auto 0 auto; }

    .features .width-2 {
        width: 47.5%;
        margin: 0 1.25%;
    }
    .features .width-2:first-child, .features .width-2:nth-child(3), .features .width-2:nth-child(2), .features .width-2:nth-child(4) {
        margin-bottom: 42px;
    }
    .features .width-2:nth-child(4) {
        clear: none;
    }
    .features .width-2:nth-child(2n+1) {
        clear: left;
    }

}

@media only screen and (max-width: 500px) {

    header .width-3.logo { margin-top: 82px; }
    .header-img .content .title-tag { margin-top: 240px; }

}

@media only screen and (max-width: 460px) {

    header .width-3.logo { margin-top: 68px; }
    .header-img .content .title-tag { margin-top: 220px; }
    .header-img .explainer { height: 220px; }

}

@media only screen and (max-width: 440px) {

    header .width-3.logo { margin-top: 56px; }
    .header-img .content .title-tag { margin-top: 200px; }
    .header-img .content .title-tag h2 { font-size: 18px; }
    .header-img .content .devices-cont { margin-top: 32px; margin-bottom: 0; }
    .header-img .content .intro { padding-top: 28px; }

    .features .width-2 {
        width: 97%;
        margin: 0 1.5%;
        margin-bottom: 42px;
    }
    .features .width-2:last-child {
        margin-bottom: 0;
    }

}


@media only screen and (max-width: 400px) {

    .header-img { background-image: none; }
    header .width-3.logo { margin-top: 8px; }
    .header-img .content .title-tag { margin-top: 92px; }


}
@media only screen and (max-width: 380px) {

    .header-img .review .reviewer-photo {
        position: relative;
        float: none;
        margin: 0 auto 12px auto;
        left: 0;
    }
    .header-img .review .review-content {
        margin-left: 0;
    }

}